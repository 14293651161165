import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Meta from "../components/meta"
import Image from "../components/image"
import Hero from "../components/hero"

export default () => (
  <Layout>
    <Meta title="車両販売" />
    <Hero title="車両販売" />
    <div className="flex-grow flex justify-center items-center">
      <div className="mx-auto px-4 sm:px-8 py-2 text-center">
        <div className="grid grid-cols-8 gap-4 items-start mt-8 mx-auto px-8">
          <div className="col-span-8 sm:col-span-8 md:col-span-4 lg:col-span-2 xl:col-span-2">
            <Link
              to="/car4/"
              className="block hover:opacity-75 duration-100"
              style={{ width: "18rem" }}
            >
              <div className="max-w-xs bg-white shadow-lg rounded-lg overflow-hidden">
                <Image
                  filename="280se.jpeg"
                  alt="メルセデスベンツ280SE"
                  className="w-full object-cover"
                />
                <div className="px-4 py-2">
                  <h1 className="text-gray-900 font-bold text-lg leading-tight">
                    <span className="block">メルセデスベンツ</span>
                    <span className="text-sm">280SEクーペ3.5</span>
                  </h1>
                </div>
                <div className="pb-4 text-gray-900">
                  {/* <span className="inline-block text-white bg-green-600 px-2 py-1 rounded text-xs">
                    ASK
                  </span> */}
                  <span className="inline-block text-white bg-red-700 px-1 py-1 rounded text-xs">
                    SOLD OUT
                  </span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-span-8 sm:col-span-8 md:col-span-4 lg:col-span-2 xl:col-span-2">
            <Link
              to="/car3/"
              className="block hover:opacity-75 duration-100"
              style={{ width: "18rem" }}
            >
              <div className="max-w-xs bg-white shadow-lg rounded-lg overflow-hidden">
                <Image
                  filename="420sel.jpeg"
                  alt="メルセデスベンツ420SEL"
                  className="w-full object-cover"
                />
                <div className="px-4 py-2">
                  <h1 className="text-gray-900 font-bold text-lg leading-tight">
                    <span className="block">メルセデスベンツ</span>
                    <span className="text-sm">420SEL</span>
                  </h1>
                </div>
                <div className="pb-4 text-gray-900">
                  <span className="inline-block text-white bg-green-600 px-2 py-1 rounded text-xs">
                    ASK
                  </span>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-span-8 sm:col-span-8 md:col-span-4 lg:col-span-2 xl:col-span-2">
            <Link
              to="/car2/"
              className="block hover:opacity-75 duration-100"
              style={{ width: "18rem" }}
            >
              <div className="max-w-xs bg-white shadow-lg rounded-lg overflow-hidden">
                <Image
                  filename="928gt.jpeg"
                  alt="ポルシェ 928GT"
                  className="w-full object-cover"
                />
                <div className="px-4 py-2">
                  <h1 className="text-gray-900 font-bold text-lg leading-tight">
                    <span className="block">ポルシェ</span>
                    <span className="text-sm">928GT</span>
                  </h1>
                </div>
                <div className="pb-4">
                  <span className="inline-block text-white bg-red-700 px-1 py-1 rounded text-xs">
                    SOLD OUT
                  </span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-span-8 sm:col-span-8 md:col-span-4 lg:col-span-2 xl:col-span-2">
            <Link
              to="/car1/"
              className="block hover:opacity-75 duration-100"
              style={{ width: "18rem" }}
            >
              <div className="max-w-xs bg-white shadow-lg rounded-lg overflow-hidden">
                <Image
                  filename="560sel.jpeg"
                  alt="メルセデスベンツAMG 560SEL"
                  className="w-full object-cover"
                />
                <div className="px-4 py-2">
                  <h1 className="text-gray-900 font-bold text-lg leading-tight">
                    <span className="block">メルセデスベンツ</span>
                    <span className="text-sm">AMG 560SEL</span>
                  </h1>
                </div>
                <div className="pb-4">
                  <span className="inline-block text-white bg-red-700 px-1 py-1 rounded text-xs">
                    SOLD OUT
                  </span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
